import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="contactInfo">Interested in purchasing this Domain?</div>
      <br/>
      <div className="contactInfo">Reach out to <b>contact@deanarvin.com</b></div>
    </div>
  );
}

export default App;
